import React, { useState, useEffect } from "react";
import debounce from "debounce";
import { HEADER_HEIGHT_SMALL_SCREEN, HEADER_HEIGHT_LARGE_SCREEN } from "../config/dimensions";
const LARGE_SCREEN_WIDTH = 600;

const useHeaderHeight = () => {
  const defaultHeaderHeight = getHeaderHeightByWindowWidth(
    typeof window !== "undefined" ? window.innerWidth : undefined
  );
  const [headerHeight, setHeaderHeight] = useState(defaultHeaderHeight);

  // handle window resize
  useEffect(() => {
    let resizeHandler;

    resizeHandler = debounce(
      () => {
        const newHeaderHeight = getHeaderHeightByWindowWidth(window.innerWidth);
        setHeaderHeight(newHeaderHeight);
      },
      200,
      undefined
    );
    window.addEventListener("resize", resizeHandler);

    return () => {
      if (resizeHandler) {
        // clear timeouts of the debounced resize handler
        // (the clear function is implemented in the debounce module)
        resizeHandler.clear && resizeHandler.clear();

        window.removeEventListener("resize", resizeHandler);
      }
    };
  }, []);

  return headerHeight;
};

function getHeaderHeightByWindowWidth(windowWidth) {
  if (windowWidth === null || windowWidth === undefined || isNaN(windowWidth)) {
    return HEADER_HEIGHT_LARGE_SCREEN;
  }

  return windowWidth >= LARGE_SCREEN_WIDTH
    ? HEADER_HEIGHT_LARGE_SCREEN
    : HEADER_HEIGHT_SMALL_SCREEN;
}

export default useHeaderHeight;
