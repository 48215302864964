import React, { useEffect, useRef, useCallback } from "react";
import { Link } from "gatsby";
import * as styles from "./navigation.module.css";

import Debug from "debug";
const debug = Debug("bitbob:components:header:navigation:naviItem:debug");

export interface Props {
  children: React.ReactChild;
  to: string;
}

const NaviItem = (props: Props) => {
  const { children, to } = props;

  const handleClick = (e) => {
    const currentUrl = new URL(window.location.href);
    const newUrl = new URL(e.target.href);

    // URL without hash stays the same
    if (currentUrl.pathname === newUrl.pathname && currentUrl.search === newUrl.search) {
      debug("handle click: path didn't change");
      // update hash
      window.location.hash = newUrl.hash;
      e.preventDefault();
    } else {
      setTimeout(() => {
        if (typeof window !== "undefined") {
          window.dispatchEvent(new Event("hashchange"));
        }
      }, 0);
      debug("handle click: path changed");
    }
  };

  return (
    <Link onClick={handleClick} to={to} className={styles.naviItem}>
      {children}
    </Link>
  );
};

export default NaviItem;
