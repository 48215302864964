import React, { useEffect, useState, useCallback, useRef, useContext } from "react";
import * as styles from "./header.module.css";
// import BitbobLogo from "../../images/bitbob-logo.svg";
import BitbobLogo from "./BitbobLogo.js";
import { useSpring, animated as a } from "react-spring";
import debounce from "debounce";
import { Link } from "gatsby";
import { ParallaxContext } from "../../context/parallax";
import useHeaderHeight from "../../hooks/useHeaderHeight";
import { BG_BLUE } from "../../config/colors";
import Navigation from "./navigation/Navigation";
import Debug from "debug";
const debug = Debug("bitbob:header:debug");

debug("run Header.js");

// original size of the unscaled box
const origWidth = 121.39;
const origHeight = 42;
const origMarginLeft = 20;

// with of the content column
const contentWidth = 600;

const calc = ({ isFullSizeAnimationEnabled, scrollTop, winWidth, winHeight }) => {
  // make sure scroll top is never negative
  scrollTop = Math.max(0, scrollTop);

  // if animation is disabled, retrun the same values as when we have scrolled down far enough
  // to display the logo in it's normal small size.
  if (!isFullSizeAnimationEnabled) {
    // return [x, y, scale, sloganOpacity];
    debug("calc(): return default values for isFullSizeAnimationEnabled=false");
    return [0, 0, 1, 0];
  }

  // use full with on small screens like mobile devices
  const isFullWidth = winWidth < 600;

  const usedScreenRatio = isFullWidth ? 0.9 : 0.5;

  // we wan't to use half the screen size
  const desiredWidth = winWidth * usedScreenRatio;
  const desiredHeight = winHeight * usedScreenRatio;

  // required scale factors to reach the desired width/height
  const requiredScaleWidth = desiredWidth / origWidth;
  const requiredScaleHeight = desiredHeight / origHeight;

  // scale will be between 1 and max scale
  const maxScale = Math.min(requiredScaleWidth, requiredScaleHeight);

  // y will be between 0 and maxY
  const maxY = winHeight / 2;

  // required scroll until end of animation
  const maxScroll = winHeight;

  const scale = Math.max(1, maxScale - ((maxScale - 1) / maxScroll) * scrollTop);

  let originalLeftEdge;
  if (isFullWidth) {
    originalLeftEdge = origMarginLeft;
  } else {
    originalLeftEdge = (winWidth - contentWidth) / 2 + origMarginLeft;
  }

  const currentWidth = origWidth * scale;
  const desiredLeftEdge = (winWidth - currentWidth) / 2;
  const maxX = desiredLeftEdge - originalLeftEdge;

  const x = maxX - (maxX / maxScroll) * Math.min(scrollTop, maxScroll);
  const y = Math.max(0, maxY - (maxY / maxScroll) * scrollTop);

  const sloganOpacity = scrollTop < winHeight - 50 ? 1 : 0;

  debug(
    `calc(): scrollTop=${scrollTop}, winWidth=${winWidth}, winHeight=${winHeight}, usedScreenRatio=${usedScreenRatio}, ` +
      `desiredWidth=${desiredWidth}, desiredHeight=${desiredHeight}, maxScale=${maxScale}, ` +
      `currentWidth=${currentWidth}, desiredLeftEdge=${desiredLeftEdge}, originalLeftEdge=${originalLeftEdge}, ` +
      `maxX=${maxX}, x=${x}, y=${y}, scale=${scale}, sloganOpacity=${sloganOpacity}`
  );

  return [x, y, scale, sloganOpacity];
};

const getTransform = (x, y, s) => `translate(${x}px, ${y}px) scale(${s})`;
const getOpacity = (x, y, s, o) => o;

const Header = (props) => {
  const headerHeight = useHeaderHeight();

  const plxContext = useContext(ParallaxContext);

  const plx = plxContext.parallaxRef?.container;

  const wasInitialized = useRef(false);

  const getCurrentDocumentProps = useCallback(() => {
    // use some defaults for SSR or if parallax container is not available yet
    if (typeof plx === "undefined" || !plx || plx.clientWidth === 0 || plx.clientHeight === 0) {
      return null;
    }
    return {
      scrollTop: plx.scrollTop,
      winWidth: plx.clientWidth,
      winHeight: plx.clientHeight,
    };
  }, [plx]);

  // render this component only on the client but not on the server (SSR)
  // because it heavily depends on window size etc which is not available
  // on the server. this would cause rehydration issues:
  // see https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
  // and https://github.com/gatsbyjs/gatsby/issues/12413
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    setRenderComponent(true);
  }, []);

  const { isFullSizeAnimationEnabled } = props;

  const doc = getCurrentDocumentProps();

  debug(`Header render. isFullSizeAnimationEnabled=${isFullSizeAnimationEnabled}, doc=`, doc);

  const [springs, springRef] = useSpring(() => ({
    // if animation is enabled we need the document props to execute calc()
    xyso:
      doc || !isFullSizeAnimationEnabled
        ? calc({ isFullSizeAnimationEnabled, ...doc })
        : [0, 0, 0, 1],
  }));

  // make sure to update animation props whenever animation is enabled/disabled
  // or when we get a new parallax ref/container
  useEffect(() => {
    const doc = getCurrentDocumentProps();
    // if animation is enabled we need the document props to execute calc()
    if (doc || !isFullSizeAnimationEnabled) {
      const springProps = {
        xyso: calc({ isFullSizeAnimationEnabled, ...doc }),
      };
      if (wasInitialized.current) {
        debug("useEffect: update", springProps);
        springRef.start(springProps);
      } else {
        debug("useEffect: init", springProps);
        springRef.start({ ...springProps, from: springProps });
      }
      wasInitialized.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullSizeAnimationEnabled, plx]);

  // handle scroll
  useEffect(() => {
    let scrollHandler;
    if (isFullSizeAnimationEnabled) {
      scrollHandler = (e) => {
        const doc = getCurrentDocumentProps();
        springRef.start({
          xyso: calc({ isFullSizeAnimationEnabled, ...doc }),
        });
      };
      plx && plx.addEventListener("scroll", scrollHandler);
    }
    return () => {
      scrollHandler && plx && plx.removeEventListener("scroll", scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullSizeAnimationEnabled, plx]);

  // handle window resize
  useEffect(() => {
    let resizeHandler;
    if (isFullSizeAnimationEnabled) {
      resizeHandler = debounce(() => {
        const doc = getCurrentDocumentProps();
        springRef.start({
          xyso: calc({ isFullSizeAnimationEnabled, ...doc }),
        });
      }, 200);
      window.addEventListener("resize", resizeHandler);
    }

    return () => {
      if (resizeHandler) {
        // clear timeouts of the debounced resize handler
        // (the clear function is implemented in the debounce module)
        resizeHandler.clear && resizeHandler.clear();

        window.removeEventListener("resize", resizeHandler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullSizeAnimationEnabled, plx]);

  // on the server don't render the component. (but we still have to
  // execute all the code above even if we don't really need it because
  // hooks must not be called conditionally.)
  if (!renderComponent || (!plx && isFullSizeAnimationEnabled)) {
    debug(
      `skip render. renderComponent=${renderComponent}, isFullSizeAnimationEnabled=${isFullSizeAnimationEnabled}, plx:`,
      plx
    );
    return null;
  }

  const logo = <BitbobLogo className={styles.logo} sloganOpacity={springs.xyso.to(getOpacity)} />;

  return (
    <>
      <div
        className={styles.header}
        style={{ height: `${headerHeight}px`, backgroundColor: BG_BLUE }}
      >
        <div className={styles.innerBox}>
          <a.div
            className={styles.logoBox}
            style={{
              transform: springs.xyso.to(getTransform),
            }}
          >
            {/* wrap logo in a link to the home page if animation is disabled */}
            {isFullSizeAnimationEnabled ? (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.hash = "";
                  const scrollTo = plxContext?.parallaxRef?.scrollTo;
                  scrollTo && scrollTo(0);
                }}
              >
                {logo}
              </a>
            ) : (
              <Link to="/">{logo}</Link>
            )}
          </a.div>
          <Navigation />
        </div>
      </div>
    </>
  );
};

export default Header;
