import React from "react";
import { animated as a } from "react-spring";
import {BLUE} from "../../config/colors";

const BitbobLogo = (props) => {
  const { className, sloganOpacity } = props;
  return (
    <svg
      className={className}
      version="1.1"
      id="layer1"
      x="0px"
      y="0px"
      width="309"
      height="84"
      viewBox="0 0 308.99999 83.999995"
      enableBackground="new 0 0 595.28 595.28"
    >
      <a.g id="software-and-energy" style={{ opacity: sloganOpacity }}>
        <g id="software">
          <path
            fill="#30394a"
            d="m 224.552,331.717 c -0.062,0.102 -0.123,0.174 -0.188,0.222 -0.064,0.046 -0.146,0.068 -0.249,0.068 -0.095,0 -0.2,-0.033 -0.312,-0.103 -0.112,-0.068 -0.244,-0.142 -0.394,-0.222 -0.149,-0.078 -0.321,-0.152 -0.514,-0.222 -0.195,-0.066 -0.421,-0.101 -0.682,-0.101 -0.457,0 -0.801,0.101 -1.03,0.307 -0.229,0.204 -0.343,0.472 -0.343,0.802 0,0.212 0.063,0.388 0.189,0.526 0.125,0.138 0.29,0.258 0.495,0.359 0.205,0.101 0.436,0.191 0.697,0.271 0.259,0.08 0.525,0.166 0.799,0.261 0.272,0.096 0.539,0.207 0.8,0.334 0.258,0.128 0.492,0.289 0.696,0.481 0.205,0.195 0.37,0.432 0.496,0.711 0.125,0.281 0.187,0.62 0.187,1.02 0,0.437 -0.076,0.846 -0.227,1.227 -0.152,0.381 -0.371,0.712 -0.656,0.996 -0.287,0.285 -0.641,0.509 -1.061,0.672 -0.421,0.162 -0.898,0.245 -1.431,0.245 -0.303,0 -0.606,-0.032 -0.908,-0.092 -0.302,-0.063 -0.593,-0.149 -0.872,-0.259 -0.28,-0.109 -0.543,-0.242 -0.79,-0.396 -0.246,-0.154 -0.462,-0.326 -0.647,-0.515 l 0.582,-0.939 c 0.049,-0.069 0.112,-0.128 0.191,-0.174 0.079,-0.046 0.163,-0.07 0.251,-0.07 0.119,0 0.245,0.044 0.377,0.132 0.133,0.089 0.284,0.187 0.455,0.29 0.172,0.106 0.371,0.203 0.598,0.292 0.226,0.087 0.5,0.132 0.816,0.132 0.462,0 0.82,-0.105 1.074,-0.317 0.253,-0.21 0.38,-0.525 0.38,-0.944 0,-0.242 -0.063,-0.438 -0.189,-0.588 -0.125,-0.149 -0.289,-0.275 -0.492,-0.377 -0.202,-0.1 -0.434,-0.188 -0.693,-0.26 -0.26,-0.073 -0.527,-0.153 -0.8,-0.242 -0.273,-0.088 -0.54,-0.194 -0.798,-0.317 -0.261,-0.123 -0.493,-0.285 -0.695,-0.487 -0.203,-0.202 -0.366,-0.453 -0.492,-0.753 -0.126,-0.301 -0.187,-0.672 -0.187,-1.116 0,-0.358 0.069,-0.703 0.21,-1.038 0.14,-0.334 0.348,-0.631 0.622,-0.893 0.272,-0.259 0.607,-0.468 1.006,-0.626 0.399,-0.159 0.856,-0.237 1.372,-0.237 0.577,0 1.11,0.088 1.601,0.266 0.49,0.18 0.906,0.426 1.245,0.744 z"
            id="path16"
            transform="translate(-143.4635,-255.709)"
          />
          <path
            fill="#30394a"
            d="m 235.901,334.669 c 0,0.7 -0.118,1.351 -0.353,1.949 -0.237,0.599 -0.569,1.116 -0.998,1.552 -0.429,0.438 -0.947,0.778 -1.549,1.026 -0.604,0.249 -1.274,0.374 -2.009,0.374 -0.734,0 -1.405,-0.125 -2.008,-0.374 -0.602,-0.248 -1.12,-0.589 -1.552,-1.026 -0.431,-0.436 -0.765,-0.953 -1,-1.552 -0.236,-0.599 -0.354,-1.249 -0.354,-1.949 0,-0.7 0.118,-1.349 0.354,-1.944 0.235,-0.599 0.569,-1.114 1,-1.55 0.432,-0.437 0.95,-0.778 1.552,-1.027 0.603,-0.249 1.274,-0.373 2.008,-0.373 0.735,0 1.405,0.124 2.009,0.373 0.602,0.249 1.12,0.593 1.549,1.029 0.429,0.438 0.761,0.956 0.998,1.554 0.235,0.595 0.353,1.243 0.353,1.938 m -2.028,0 c 0,-0.502 -0.066,-0.953 -0.198,-1.352 -0.132,-0.399 -0.323,-0.737 -0.572,-1.017 -0.25,-0.279 -0.55,-0.492 -0.905,-0.642 -0.354,-0.146 -0.756,-0.22 -1.206,-0.22 -0.448,0 -0.851,0.073 -1.208,0.22 -0.357,0.149 -0.66,0.362 -0.908,0.642 -0.249,0.279 -0.44,0.618 -0.572,1.017 -0.132,0.398 -0.198,0.85 -0.198,1.352 0,0.506 0.066,0.958 0.198,1.357 0.132,0.398 0.323,0.735 0.572,1.014 0.248,0.277 0.551,0.489 0.908,0.638 0.357,0.147 0.76,0.221 1.208,0.221 0.45,0 0.852,-0.073 1.206,-0.221 0.355,-0.148 0.655,-0.36 0.905,-0.638 0.249,-0.278 0.44,-0.615 0.572,-1.014 0.132,-0.399 0.198,-0.851 0.198,-1.357"
            id="path18"
            transform="translate(-143.4635,-255.709)"
          />
          <polygon
            fill="#30394a"
            points="240.669,331.433 240.669,334.081 244.117,334.081 244.117,335.64 240.669,335.64 240.669,339.465 238.675,339.465 238.675,329.88 244.777,329.88 244.777,331.433 "
            id="polygon20"
            transform="translate(-143.4635,-255.709)"
          />
          <polygon
            fill="#30394a"
            points="251.457,339.465 249.469,339.465 249.469,331.479 246.667,331.479 246.667,329.88 254.258,329.88 254.258,331.479 251.457,331.479 "
            id="polygon22"
            transform="translate(-143.4635,-255.709)"
          />
          <path
            fill="#30394a"
            d="m 269.668,329.88 -2.973,9.585 h -1.79 l -1.962,-6.27 c -0.031,-0.086 -0.061,-0.185 -0.091,-0.29 -0.032,-0.106 -0.061,-0.217 -0.086,-0.337 -0.027,0.12 -0.056,0.23 -0.087,0.337 -0.031,0.105 -0.061,0.204 -0.092,0.29 l -1.982,6.27 h -1.796 l -2.967,-9.585 h 1.665 c 0.172,0 0.316,0.039 0.433,0.119 0.116,0.079 0.192,0.185 0.228,0.317 l 1.486,5.403 c 0.035,0.149 0.072,0.312 0.109,0.484 0.037,0.175 0.073,0.359 0.109,0.552 0.039,-0.197 0.081,-0.383 0.125,-0.555 0.045,-0.172 0.094,-0.332 0.146,-0.481 l 1.73,-5.403 c 0.035,-0.111 0.111,-0.21 0.229,-0.301 0.116,-0.091 0.258,-0.136 0.425,-0.136 h 0.582 c 0.172,0 0.312,0.04 0.418,0.122 0.109,0.081 0.19,0.187 0.243,0.314 l 1.723,5.403 c 0.053,0.141 0.102,0.295 0.145,0.458 0.045,0.166 0.086,0.341 0.126,0.526 0.035,-0.186 0.07,-0.36 0.102,-0.526 0.033,-0.163 0.068,-0.317 0.104,-0.458 l 1.486,-5.403 c 0.029,-0.115 0.105,-0.216 0.224,-0.303 0.118,-0.09 0.261,-0.134 0.428,-0.134 h 1.56 z"
            id="path24"
            transform="translate(-143.4635,-255.709)"
          />
          <path
            fill="#30394a"
            d="m 279.801,339.466 h -1.54 c -0.172,0 -0.311,-0.042 -0.418,-0.122 -0.108,-0.081 -0.189,-0.188 -0.242,-0.315 l -0.648,-1.83 h -3.817 l -0.647,1.83 c -0.044,0.11 -0.122,0.211 -0.231,0.301 -0.111,0.092 -0.25,0.137 -0.416,0.137 h -1.553 l 3.745,-9.585 h 2.029 z m -3.329,-3.641 -1.036,-2.934 c -0.063,-0.153 -0.127,-0.334 -0.194,-0.541 -0.069,-0.208 -0.134,-0.432 -0.197,-0.673 -0.06,0.247 -0.127,0.474 -0.194,0.683 -0.068,0.208 -0.133,0.392 -0.195,0.545 l -1.031,2.92 z"
            id="path26"
            transform="translate(-143.4635,-255.709)"
          />
          <path
            fill="#30394a"
            d="m 289.651,339.465 h -1.791 c -0.335,0 -0.577,-0.128 -0.727,-0.384 l -1.823,-2.938 c -0.075,-0.115 -0.158,-0.199 -0.248,-0.25 -0.09,-0.055 -0.223,-0.08 -0.4,-0.08 h -0.673 v 3.652 h -1.982 v -9.585 h 3.013 c 0.669,0 1.242,0.069 1.716,0.208 0.476,0.14 0.867,0.332 1.17,0.578 0.304,0.247 0.526,0.54 0.666,0.881 0.142,0.343 0.212,0.719 0.212,1.127 0,0.317 -0.045,0.617 -0.135,0.901 -0.09,0.284 -0.221,0.543 -0.393,0.777 -0.172,0.233 -0.384,0.44 -0.634,0.622 -0.252,0.18 -0.538,0.324 -0.859,0.434 0.128,0.071 0.246,0.156 0.357,0.255 0.11,0.1 0.209,0.217 0.296,0.354 z m -4.645,-5.047 c 0.321,0 0.6,-0.042 0.832,-0.122 0.234,-0.081 0.425,-0.192 0.574,-0.337 0.152,-0.144 0.261,-0.312 0.332,-0.506 0.07,-0.192 0.105,-0.404 0.105,-0.634 0,-0.454 -0.152,-0.806 -0.452,-1.056 -0.302,-0.252 -0.761,-0.378 -1.377,-0.378 h -1.031 v 3.032 h 1.017 z"
            id="path28"
            transform="translate(-143.4635,-255.709)"
          />
          <polygon
            fill="#30394a"
            points="298.012,329.88 298.012,331.433 293.903,331.433 293.903,333.896 297.101,333.896 297.101,335.396 293.903,335.396 293.903,337.912 298.012,337.912 298.012,339.465 291.909,339.465 291.909,329.88 "
            id="polygon30"
            transform="translate(-143.4635,-255.709)"
          />
        </g>
        <path
          fill="#30394a"
          d="m 313.886,339.465 h -1.79 c -0.093,0 -0.175,-0.004 -0.248,-0.014 -0.073,-0.009 -0.14,-0.026 -0.201,-0.053 -0.062,-0.026 -0.123,-0.062 -0.179,-0.105 -0.058,-0.044 -0.116,-0.099 -0.179,-0.165 l -0.542,-0.567 c -0.422,0.321 -0.889,0.568 -1.396,0.747 -0.508,0.174 -1.054,0.263 -1.635,0.263 -0.375,0 -0.742,-0.064 -1.104,-0.195 -0.36,-0.13 -0.682,-0.313 -0.964,-0.555 -0.281,-0.24 -0.508,-0.53 -0.68,-0.872 -0.172,-0.342 -0.258,-0.724 -0.258,-1.152 0,-0.295 0.046,-0.573 0.14,-0.839 0.091,-0.265 0.223,-0.509 0.393,-0.732 0.169,-0.226 0.369,-0.431 0.602,-0.615 0.23,-0.186 0.484,-0.343 0.762,-0.476 -0.224,-0.324 -0.386,-0.645 -0.485,-0.954 -0.099,-0.311 -0.148,-0.617 -0.148,-0.923 0,-0.333 0.062,-0.651 0.185,-0.953 0.122,-0.303 0.302,-0.566 0.535,-0.792 0.233,-0.228 0.521,-0.406 0.862,-0.538 0.342,-0.133 0.73,-0.199 1.167,-0.199 0.404,0 0.765,0.06 1.082,0.182 0.317,0.121 0.586,0.283 0.806,0.484 0.221,0.204 0.395,0.434 0.519,0.695 0.126,0.261 0.202,0.529 0.229,0.812 l -1.164,0.238 c -0.214,0.044 -0.372,-0.043 -0.467,-0.265 -0.089,-0.229 -0.211,-0.417 -0.369,-0.564 -0.154,-0.148 -0.368,-0.221 -0.635,-0.221 -0.169,0 -0.319,0.029 -0.45,0.09 -0.132,0.058 -0.244,0.139 -0.334,0.239 -0.09,0.1 -0.159,0.221 -0.205,0.354 -0.045,0.135 -0.069,0.278 -0.069,0.427 0,0.123 0.013,0.241 0.036,0.357 0.025,0.113 0.065,0.229 0.119,0.345 0.056,0.117 0.128,0.238 0.223,0.361 0.092,0.123 0.204,0.255 0.336,0.396 l 2.465,2.575 c 0.136,-0.267 0.244,-0.55 0.322,-0.848 0.08,-0.299 0.134,-0.603 0.16,-0.914 0.012,-0.116 0.051,-0.206 0.115,-0.272 0.063,-0.065 0.151,-0.098 0.261,-0.098 h 1.143 c 0,0.606 -0.085,1.181 -0.255,1.723 -0.169,0.541 -0.405,1.039 -0.71,1.493 z m -6.468,-4.38 c -0.305,0.221 -0.521,0.463 -0.653,0.728 -0.133,0.264 -0.199,0.543 -0.199,0.838 0,0.229 0.038,0.435 0.113,0.618 0.074,0.183 0.177,0.338 0.309,0.468 0.135,0.131 0.289,0.231 0.471,0.302 0.181,0.069 0.374,0.106 0.582,0.106 0.343,0 0.661,-0.05 0.954,-0.15 0.293,-0.098 0.56,-0.24 0.803,-0.426 z"
          id="path32"
          transform="translate(-143.4635,-255.709)"
        />
        <g id="energy">
          <polygon
            fill="#30394a"
            points="326.771,329.88 326.771,331.433 322.664,331.433 322.664,333.896 325.86,333.896 325.86,335.396 322.664,335.396 322.664,337.912 326.771,337.912 326.771,339.465 320.669,339.465 320.669,329.88 "
            id="polygon34"
            transform="translate(-143.4635,-255.709)"
          />
          <path
            fill="#30394a"
            d="m 337.785,329.88 v 9.585 h -1.023 c -0.154,0 -0.285,-0.024 -0.39,-0.075 -0.107,-0.053 -0.21,-0.139 -0.312,-0.262 l -4.775,-6.077 c 0.014,0.153 0.022,0.307 0.029,0.455 0.007,0.15 0.011,0.289 0.011,0.416 v 5.543 h -1.751 v -9.585 h 1.044 c 0.084,0 0.154,0.003 0.215,0.01 0.059,0.008 0.113,0.021 0.161,0.048 0.049,0.022 0.095,0.055 0.139,0.094 0.045,0.04 0.091,0.093 0.146,0.16 l 4.81,6.103 c -0.018,-0.167 -0.032,-0.329 -0.04,-0.485 -0.009,-0.156 -0.014,-0.305 -0.014,-0.446 v -5.482 h 1.75 z"
            id="path36"
            transform="translate(-143.4635,-255.709)"
          />
          <polygon
            fill="#30394a"
            points="347.216,329.88 347.216,331.433 343.107,331.433 343.107,333.896 346.305,333.896 346.305,335.396 343.107,335.396 343.107,337.912 347.216,337.912 347.216,339.465 341.113,339.465 341.113,329.88 "
            id="polygon38"
            transform="translate(-143.4635,-255.709)"
          />
          <path
            fill="#30394a"
            d="m 357.662,339.465 h -1.791 c -0.335,0 -0.577,-0.128 -0.728,-0.384 l -1.822,-2.938 c -0.075,-0.115 -0.158,-0.199 -0.248,-0.25 -0.09,-0.055 -0.223,-0.08 -0.4,-0.08 H 352 v 3.652 h -1.981 v -9.585 h 3.013 c 0.669,0 1.242,0.069 1.716,0.208 0.477,0.14 0.866,0.332 1.17,0.578 0.305,0.247 0.526,0.54 0.666,0.881 0.142,0.343 0.212,0.719 0.212,1.127 0,0.317 -0.045,0.617 -0.135,0.901 -0.09,0.284 -0.221,0.543 -0.393,0.777 -0.172,0.233 -0.384,0.44 -0.634,0.622 -0.252,0.18 -0.538,0.324 -0.86,0.434 0.129,0.071 0.247,0.156 0.358,0.255 0.109,0.1 0.209,0.217 0.296,0.354 z m -4.644,-5.047 c 0.32,0 0.599,-0.042 0.832,-0.122 0.233,-0.081 0.425,-0.192 0.574,-0.337 0.15,-0.144 0.261,-0.312 0.33,-0.506 0.071,-0.192 0.105,-0.404 0.105,-0.634 0,-0.454 -0.151,-0.806 -0.452,-1.056 -0.301,-0.252 -0.76,-0.378 -1.376,-0.378 H 352 v 3.032 h 1.018 z"
            id="path40"
            transform="translate(-143.4635,-255.709)"
          />
          <path
            fill="#30394a"
            d="m 364.432,334.583 h 3.29 v 3.957 c -0.489,0.356 -1.011,0.616 -1.562,0.782 -0.553,0.166 -1.137,0.248 -1.754,0.248 -0.788,0 -1.501,-0.122 -2.141,-0.366 -0.638,-0.245 -1.186,-0.586 -1.638,-1.021 -0.454,-0.438 -0.804,-0.954 -1.051,-1.554 -0.247,-0.598 -0.371,-1.252 -0.371,-1.961 0,-0.718 0.12,-1.376 0.355,-1.976 0.235,-0.597 0.571,-1.114 1.007,-1.545 0.436,-0.433 0.964,-0.769 1.582,-1.011 0.619,-0.241 1.312,-0.363 2.078,-0.363 0.395,0 0.766,0.032 1.109,0.096 0.343,0.064 0.659,0.152 0.95,0.266 0.29,0.11 0.558,0.244 0.801,0.397 0.24,0.154 0.462,0.324 0.659,0.51 l -0.573,0.885 c -0.09,0.141 -0.203,0.227 -0.345,0.258 -0.14,0.03 -0.295,-0.003 -0.462,-0.106 -0.159,-0.097 -0.313,-0.184 -0.466,-0.261 -0.153,-0.077 -0.313,-0.145 -0.486,-0.201 -0.172,-0.057 -0.358,-0.101 -0.56,-0.133 -0.203,-0.03 -0.437,-0.046 -0.7,-0.046 -0.446,0 -0.849,0.078 -1.21,0.23 -0.362,0.154 -0.668,0.373 -0.921,0.654 -0.254,0.283 -0.448,0.622 -0.585,1.018 -0.136,0.396 -0.205,0.84 -0.205,1.328 0,0.528 0.075,1.001 0.224,1.417 0.15,0.416 0.362,0.769 0.635,1.055 0.273,0.284 0.601,0.503 0.982,0.653 0.379,0.149 0.805,0.225 1.271,0.225 0.316,0 0.601,-0.033 0.85,-0.097 0.248,-0.063 0.492,-0.15 0.729,-0.26 v -1.612 h -1.083 c -0.129,0 -0.229,-0.034 -0.302,-0.103 -0.072,-0.068 -0.108,-0.155 -0.108,-0.261 v -1.102 z"
            id="path42"
            transform="translate(-143.4635,-255.709)"
          />
          <path
            fill="#30394a"
            d="m 374.729,335.778 v 3.688 h -1.98 v -3.688 l -3.482,-5.897 h 1.744 c 0.172,0 0.309,0.04 0.411,0.122 0.104,0.081 0.188,0.185 0.248,0.312 l 1.573,3.021 c 0.106,0.201 0.202,0.391 0.288,0.567 0.086,0.176 0.162,0.352 0.227,0.528 0.062,-0.177 0.135,-0.354 0.216,-0.532 0.081,-0.179 0.173,-0.366 0.273,-0.563 l 1.559,-3.021 c 0.054,-0.104 0.133,-0.205 0.237,-0.297 0.106,-0.092 0.244,-0.138 0.411,-0.138 h 1.756 z"
            id="path44"
            transform="translate(-143.4635,-255.709)"
          />
        </g>
      </a.g>
      <path
        fill="#30394a"
        d="m 170.481,324.519 c -0.885,0 -1.771,-0.337 -2.444,-1.012 l -23.561,-23.56 c -1.35,-1.352 -1.35,-3.541 0,-4.891 l 23.561,-23.561 c 1.348,-1.35 3.539,-1.35 4.888,0 1.351,1.35 1.351,3.54 0,4.889 l -21.115,21.116 21.115,21.117 c 1.351,1.349 1.351,3.539 0,4.889 -0.674,0.676 -1.559,1.013 -2.444,1.013"
        id="bracket-left"
        transform="translate(-143.4635,-255.709)"
      />
      <path
        fill="#30394a"
        d="m 424.799,324.519 c -0.885,0 -1.771,-0.337 -2.444,-1.012 -1.351,-1.35 -1.351,-3.54 0,-4.889 l 21.116,-21.117 -21.116,-21.116 c -1.351,-1.349 -1.351,-3.538 0,-4.889 1.349,-1.35 3.54,-1.35 4.888,0 l 23.562,23.561 c 0.648,0.649 1.013,1.527 1.013,2.444 0,0.917 -0.364,1.796 -1.013,2.446 l -23.562,23.56 c -0.675,0.675 -1.559,1.012 -2.444,1.012"
        id="bracket-right"
        transform="translate(-143.4635,-255.709)"
      />
      <g id="letter-i">
        <path
          d="m 244.625,261.582 c -0.056,-0.099 -0.16,-0.16 -0.274,-0.16 h -4.126 l 0.691,-5.286 c 0.019,-0.174 -0.108,-0.33 -0.282,-0.348 -0.118,-0.013 -0.234,0.042 -0.299,0.142 l -5.019,8.792 c -0.09,0.149 -0.045,0.342 0.104,0.434 0.05,0.03 0.106,0.047 0.165,0.047 h 4.065 l -0.548,5.294 c -0.013,0.174 0.117,0.327 0.292,0.34 0.117,0.009 0.229,-0.047 0.292,-0.147 l 4.935,-8.791 c 0.059,-0.098 0.06,-0.219 0.004,-0.317"
          id="bolt"
          style={{ fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        />
        <rect
          x="238.498"
          y="272.435"
          width="3.842"
          height="47.631001"
          id="rect54"
          transform="matrix(1,0,0,0.83968423,-143.4635,-204.53837)"
          style={{ fill: BLUE, fillOpacity: 1 }}
        />
      </g>
      <path
        d="m 403.53,319.925 h -25.062 c -4.116,0 -7.466,-3.35 -7.466,-7.467 v -56.737 h 3.841 v 56.737 c 0,1.999 1.626,3.625 3.625,3.625 h 25.062 c 1.999,0 3.624,-1.626 3.624,-3.625 v -25.061 c 0,-1.999 -1.625,-3.625 -3.624,-3.625 h -25.062 v -3.842 h 25.062 c 4.116,0 7.466,3.349 7.466,7.467 v 25.061 c 0,4.117 -3.35,7.467 -7.466,7.467"
        id="path64"
        style={{ fill: BLUE, fillOpacity: 1 }}
        transform="translate(-143.4635,-255.709)"
      />
      <path
        d="m 312.639,319.925 h -25.062 c -4.118,0 -7.467,-3.35 -7.467,-7.467 v -56.737 h 3.842 v 56.737 c 0,1.999 1.625,3.625 3.625,3.625 h 25.062 c 1.998,0 3.624,-1.626 3.624,-3.625 v -25.061 c 0,-1.999 -1.626,-3.625 -3.624,-3.625 h -25.062 v -3.842 h 25.062 c 4.116,0 7.466,3.349 7.466,7.467 v 25.061 c -0.001,4.117 -3.35,7.467 -7.466,7.467"
        id="letter-b2"
        style={{ fill: BLUE, fillOpacity: 1 }}
        transform="translate(-143.4635,-255.709)"
      />
      <g id="letter-b1">
        <path
          d="m 221.422,319.906 h -28.135 c -4.117,0 -7.467,-3.349 -7.467,-7.466 v -49.264 c 0,-4.118 3.35,-7.467 7.467,-7.467 h 28.135 c 4.117,0 7.467,3.349 7.467,7.467 v 7.131 h -3.842 v -7.131 c 0,-1.999 -1.626,-3.625 -3.625,-3.625 h -28.135 c -1.999,0 -3.625,1.626 -3.625,3.625 v 49.264 c 0,1.998 1.626,3.625 3.625,3.625 h 28.135 c 1.999,0 3.625,-1.627 3.625,-3.625 v -25.062 c 0,-1.999 -1.626,-3.625 -3.625,-3.625 h -28.135 v -3.842 h 28.135 c 4.117,0 7.467,3.349 7.467,7.467 v 25.062 c 0,4.118 -3.35,7.466 -7.467,7.466"
          id="path68"
          style={{ fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        />
        <path
          d="m 221.422,283.752 h -28.135 v -3.841 h 28.135 c 1.999,0 3.625,-1.627 3.625,-3.625 v -12.347 h 3.842 v 12.347 c 0,4.117 -3.35,7.466 -7.467,7.466"
          id="path70"
          style={{ fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        />
      </g>
      <g id="letter-t">
        <rect
          x="250.993"
          y="274.71301"
          width="21.249001"
          height="3.8410001"
          id="rect52"
          transform="translate(-143.4635,-250.49201)"
          style={{ fill: BLUE, fillOpacity: 1 }}
        />
        <path
          d="m 272.242,319.925 h -8.898 c -4.117,0 -7.466,-3.35 -7.466,-7.467 v -56.737 h 3.842 v 56.737 c 0,1.999 1.626,3.625 3.624,3.625 h 8.898 z"
          id="path72"
          style={{ fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        />
      </g>
      <g id="letter-o">
        <path
          d="m 333.152,286.103 c 0,0.584 -0.475,1.058 -1.059,1.058 -0.585,0 -1.058,-0.474 -1.058,-1.058 0,-0.585 0.473,-1.059 1.058,-1.059 0.585,0 1.059,0.474 1.059,1.059"
          id="path56"
          style={{ fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        />
        <path
          d="m 336.16,286.103 c 0,0.584 -0.475,1.058 -1.06,1.058 -0.584,0 -1.057,-0.474 -1.057,-1.058 0,-0.585 0.473,-1.059 1.057,-1.059 0.586,0 1.06,0.474 1.06,1.059"
          id="path58"
          style={{ fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        />
        <path
          d="m 339.167,286.103 c 0,0.584 -0.475,1.058 -1.059,1.058 -0.584,0 -1.058,-0.474 -1.058,-1.058 0,-0.585 0.474,-1.059 1.058,-1.059 0.584,0 1.059,0.474 1.059,1.059"
          id="path60"
          style={{ fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        />
        <rect
          x="325.81799"
          y="288.37399"
          width="36.721001"
          height="3.8410001"
          id="rect62"
          style={{ fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        />
        <path
          d="m 357.265,319.925 h -25.062 c -4.116,0 -7.466,-3.349 -7.466,-7.466 v -25.062 c 0,-4.117 3.35,-7.467 7.466,-7.467 h 25.062 c 4.118,0 7.468,3.35 7.468,7.467 v 25.062 c -10e-4,4.117 -3.35,7.466 -7.468,7.466 m -25.062,-36.153 c -1.998,0 -3.624,1.626 -3.624,3.625 v 25.062 c 0,1.999 1.626,3.625 3.624,3.625 h 25.062 c 2,0 3.625,-1.626 3.625,-3.625 v -25.062 c 0,-1.999 -1.625,-3.625 -3.625,-3.625 z"
          id="path74"
          style={{ fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        />
        <g
          opacity="0.1"
          id="g86"
          style={{ opacity: 0.1, fill: BLUE, fillOpacity: 1 }}
          transform="translate(-143.4635,-255.709)"
        >
          <path
            d="m 359.623,317.073 h -29.85 c -1.61,0 -2.917,-1.306 -2.917,-2.916 v -20.603 c 0,-1.61 1.307,-2.916 2.917,-2.916 h 29.85 c 1.609,0 2.916,1.306 2.916,2.916 v 20.603 c 0,1.611 -1.307,2.916 -2.916,2.916"
            id="path82"
            style={{ fill: BLUE, fillOpacity: 1 }}
          />
        </g>
      </g>
    </svg>
  );
};

export default BitbobLogo;
