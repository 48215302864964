import React, { useEffect, useState } from "react";
import Content from "./Content";
import "./colors.css";
import "./dimensions.css";
import "./layout.css";
import { ParallaxContextProvider } from "../../context/parallax";
import Header from "../header/Header";

// This ensures that the icon CSS is loaded immediately before attempting to render icons
// see here: https://github.com/FortAwesome/react-fontawesome/issues/134#issuecomment-471940596
import "@fortawesome/fontawesome-svg-core/styles.css";
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import useHeaderHeight from "../../hooks/useHeaderHeight";

import Debug from "debug";
const debug = Debug("bitbob:components:layout:debug");

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;

// init fontawesome
library.add(fas);
library.add(fab);

const Layout = (props) => {
  const headerHeight = useHeaderHeight();

  const { location, children } = props;
  const isHome = location.pathname === "/";

  const [isInitScrolling, setIsInitScrolling] = useState(false);

  useEffect(() => {
    debug('useEffect hash');
    let timeout;
    const hashChangeHandler = () => {
      const hasHash = currentLocationHasHash();
      debug(`hash changed. hasHash=${hasHash}`);
      setIsInitScrolling(hasHash);
      if (hasHash) {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          setIsInitScrolling(false);
          timeout = null;
        }, 1000);
      }
    };
    window.addEventListener("hashchange", hashChangeHandler);

    hashChangeHandler();

    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };
  }, []);

  debug("isInitScrolling", isInitScrolling);

  return (
    <ParallaxContextProvider>
      <Header isFullSizeAnimationEnabled={isHome && !isInitScrolling} />
      {isHome ? children : <Content style={{ marginTop: `${headerHeight}px` }}>{children}</Content>}
    </ParallaxContextProvider>
  );
};

function currentLocationHasHash() {
  if (typeof window === "undefined") {
    return false;
  }
  return Boolean(window.location.hash && window.location.hash.length > 1);
}

export default Layout;
