import React, { useState, useEffect } from "react";
import { ParallaxApi, PlxGroundedLayer } from "../../parallax/Parallax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useSpring, animated as a } from "react-spring";
import * as styles from "./navigation.module.css";
import NaviItem from "./NaviItem";

import Debug from "debug";
const debug = Debug("bitbob:pages:index:headerLayer:debug");

interface NavigationProps {
  parentLayerRef: React.MutableRefObject<HTMLDivElement>;
  parallax: ParallaxApi;
}

const Navigation = (props: NavigationProps) => {
  // const { parentLayerRef, parallax } = props;

  // // used to fade in/out the scroll down div by animating it's opacity with react-spring
  // const [isScrollDownHidden, setIsScrollDownHidden] = useState(false);

  // // used to completely hide the scroll down div by setting it's display=none after
  // // the fade out animation completed. otherwise the link would still be clickable
  // // even if it is invisible.
  // const [isScrollDownRemoved, setIsScrollDownRemoved] = useState(false);

  // // scroll handler to show/hide "scroll down" link
  // useEffect(() => {
  //   const scrollHandler = () => {
  //     const hide = parallax && parallax.container ? parallax.container.scrollTop >= 50 : false;

  //     if (!hide) {
  //       // immediately remove display=none to allow div to fade in
  //       setIsScrollDownRemoved(false);
  //     }

  //     // fade in or out
  //     setIsScrollDownHidden(hide);
  //   };
  //   parallax && parallax.events.on("scroll", scrollHandler);
  //   return () => {
  //     parallax && parallax.events.removeListener("scroll", scrollHandler);
  //   };
  // }, [parallax]);

  // const spring = useSpring({
  //   opacity: isScrollDownHidden ? 0 : 1,
  //   onRest: () => {
  //     if (isScrollDownHidden) {
  //       setIsScrollDownRemoved(true);
  //       debug(`fading out complete. set isScrollDownRemoved=true`);
  //     }
  //   },
  // });

  return (
    // <a
    //   href="#software"
    //   onClick={(e) => {
    //     e.preventDefault();
    //     if (!isScrollDownHidden) {
    //       parallax.scrollTo("software");
    //     }
    //   }}
    // >
    <a.div
      // style={spring}
      // className={classnames(styles.naviItems, {
      //   [styles.removed]: isScrollDownRemoved,
      // })}
      className={styles.navigation}
    >
      <NaviItem to="/#software">Software</NaviItem>
      <NaviItem to="/#energy">Energy</NaviItem>
      <NaviItem to="/#about">Bitbob</NaviItem>
      <NaviItem to="/contact">Kontakt</NaviItem>
    </a.div>
  );
};

export default Navigation;
