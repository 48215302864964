import React, { useState } from "react";
import type { ParallaxApi } from "../components/parallax/Parallax";

export const ParallaxContext = React.createContext({});

export const ParallaxContextProvider = (props) => {
  const [parallaxRef, setParallaxRef] = useState<ParallaxApi>();
  return (
    <ParallaxContext.Provider
      value={{
        parallaxRef,
        setParallaxRef,
      }}
    >
      {props.children}
    </ParallaxContext.Provider>
  );
};
