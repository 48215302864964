import React from "react";
import * as styles from "./content.module.css";

const Content = (props) => {
  const { style, children } = props;

  return (
    <div className={styles.main} style={style}>
      {children}
    </div>
  );
};

export default Content;
